 

function getter(json) {
   return function(key) {
      if (!json.hasOwnProperty(key)) {
         var errorStr = 'Property ' + key + ' does not exist!';
         throw errorStr;
      }
      return json[key];
   };
}

const shared_constants = {
   BaseURI: getter({
      SITE_IMAGE_LOADING_SMALL: 'https://assets.cdn.ifixit.com/static/images/loading-small.gif',
      TAG_CHECKOUT_CART: 'https://www.ifixit.com/cart',
      STORE: 'https://www.ifixit.com/Store',
      SITE_IMAGE_BLANK: 'https://assets.cdn.ifixit.com/static/images/blank.gif',
      SITE_IMAGE_BLURBS_LOADING: 'https://assets.cdn.ifixit.com/static/images/guide/blurbs-loading.gif',
      SITE_IMAGE_AJAX_LOAD: 'https://assets.cdn.ifixit.com/static/images/sales/ajax_load.gif',
   }),

   Global: getter({
      EMBED_ASPECT_RATIO: parseFloat('0.73'),
      LARGE_EMBED_HEIGHT: parseInt('529', 10),
      REGULAR_EMBED_HEIGHT:  parseInt('357', 10)
   }),

   GuideURI: getter({
      TOPIC_NO_IMAGE_MINI: 'https://assets.cdn.ifixit.com/static/images/default_images/DeviceNoImage_56x42.jpg',
      NO_IMAGE_MINI: 'https://assets.cdn.ifixit.com/static/images/guide/NoImage_56x42.jpg',
      GUIDE_NO_MAIN_IMAGE_MINI: 'https://assets.cdn.ifixit.com/static/images/default_images/GuideNoImage_56x42.jpg',
      GUIDE_IMAGE_REDIRECT_ORIGINAL: '/Guide/Image/meta/redirect/original/',
      GUIDE_IMAGE_METADATA: '/Guide/Image/meta/',
      PREVIEW_IMAGE: '/Guide/image/preview',

      TAG_LOGOUT: '/Guide/logout',
      TAG_SSO_CLOSE_WINDOW: '/CLOSE_THIS_WINDOW',
      TAG_USER_DETAILS: '/Guide/User/index/details',
      TAG_LOGIN: '/Login',
      TAG_SITE_HOMEPAGE: '/',
      TAG_SEARCH: '/Search'
   }),
}
window.shared_constants = shared_constants;
export default shared_constants;
